$(document).on("turbolinks:load", () => {
  // Menu mobile

  let menu_opened = false;

  $('.menu_icon').on('click', function () {
    $(this).siblings('.offcanvas-menu').removeClass('closed');
    menu_opened = true;
  });

  const $menu = $('.mobile_content');

  $(document).mouseup(e => {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0 && menu_opened) {
      $('.menu_icon').siblings('.offcanvas-menu').addClass('closed');
    }
  });

  // Suppression des paramètres

  let href = window.location.href;

  if (href.indexOf('?a=') > 0) {
    let url = href.split('?a=');
    history.pushState(null, null, url[0]);
  } else if (href.indexOf('&a=') > 0) {
    let url = href.split('&a=');
    history.pushState(null, null, url[0]);
  }

  // Scroll section

  $('.scroll_link').click(function() {
    const yOffset = - 75;
    const element = document.getElementById($(this).attr('data-anchor'));
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  });

  // Copier-coller

  function copyToClipboard(text) {
    let $temp = $("<textarea class='preserve-whitespace'>");
    $("body").append($temp);
    $temp.val(text).select();
    document.execCommand("copy");
    $temp.remove();
    toastr["success"]("Le texte a été copié !", '', {timeOut: 1000});
  }

  function copyTextareaToClipboard(textarea) {
    textarea.select();
    document.execCommand("copy");
    toastr["success"]("Le texte a été copié !", '', {timeOut: 1000});
  }

  $(".copy_text_button").on('click', function() {
    copyToClipboard($(this).data('text'));
  });

  $('.copy_textarea_button').on('click', function() {
    copyTextareaToClipboard($('#' + $(this).data('textarea')));
  });

  $('.anchor_link').click(function() {
    const yOffset = - $($(this).attr('data-parent-id')).height();
    const element = document.getElementById($(this).attr('data-anchor'));
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  });

  // Empêcher le copier-coller

  $('.no-copy').bind("cut copy", function(e) {
    e.preventDefault();
  });

  $('.no-copy').on("contextmenu", function(e) {
    return false;
  });

  // Affichage des vidéos YouTube ou Vimeo

  window.youtube_id = function youtube_id(video_url) {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = video_url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return 'error';
    }
  }

  window.vimeo_id = function vimeo_id(video_url) {
    let regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    let match = video_url.match(regExp);

    if (match){
      return match[2];
    }
    else {
      return 'error';
    }
  }

  let youtube_videos = [];
  let vimeo_player;

  window.init_player = function init_player(element) {
    let video_url = element.data('url');
    let video_type = element.data('type');
    let container_id = element.data('id');

    if (video_url != null && video_url !== '') {
      if (video_type === 'youtube') {
        if (typeof YT !== 'undefined') {
          try {
            new YT.Player(container_id, {
              height: '360',
              width: '640',
              videoId: youtube_id(video_url),
            });
          } catch(err) {
            console.log('err', err);
            setTimeout(() => {
              init_player(element);
            }, 1000);
          }
        } else {
          let video = [container_id, video_url]
          youtube_videos.push(video);

          window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
            youtube_videos.forEach(function(item, index, array) {
              new YT.Player(item[0], {
                height: '360',
                width: '640',
                videoId: youtube_id(item[1]),
              });
            });
          };
        }
      } else {
        $('#' + container_id).attr('data-vimeo-url', video_url);

        if (typeof Vimeo !== 'undefined' && typeof Vimeo.Player !== 'undefined') {
          vimeo_player = new Vimeo.Player(container_id)
        }
      }
    }
  }

  $(".video_player").each(function() {
    init_player($(this));
  });

  /* setting background images */
  $('.bg_image_holder').each(function () {
    var $this = $(this);
    var imgLink = $this.children().attr('src');
    $this.css({
      "background-image": "url(" + imgLink + ")",
      "opacity": "1"
    }).children().attr('alt', imgLink);
  });

  // Carousel

  $('.item-thumbnail').click(function() {
    $('.video_player').removeAttr('data-vimeo-url').removeAttr('data-vimeo-initialized').removeClass('.video_player');
    $('#player').removeAttr('id');

    if ($(this).hasClass('video-thumbnail')) {
      let player_container = $('.current_media');

      player_container.children('img').remove();
      player_container.children('iframe').remove();

      let video_type = $(this).data('type');
      let video_url = $(this).data('url');

      if (video_type === 'youtube') {
        player_container.addClass('video_player');
        player_container.data('id', "player");
        player_container.html('<div id="player"></div>');
      } else if (video_type === 'vimeo') {
        if (typeof vimeo_player !== 'undefined') {
          vimeo_player.destroy();
        }

        player_container.addClass('video_player');
        player_container.data('id', "player");
        player_container.attr('id', "player");
      }

      if (video_type === 'youtube' || video_type === 'vimeo') {
        let video_player = $('.current_media');
        video_player.data('url', video_url);
        video_player.data('type', video_type);
        init_player(video_player);
      }
    } else {
      $('.current_media').html('<img src="" />');
      $('.current_media img').attr("src", $(this).children('img').attr("src"));
    }
  });

  // Thumbnail YouTube et Vimeo

  function youtube_thumbnail(video_url) {
    return "https://img.youtube.com/vi/"+ youtube_id(video_url) + "/mqdefault.jpg";
  }

  function vimeo_thumbnail(video_url) {
    return new Promise(function(resolve, reject) {
      $.getJSON('http://www.vimeo.com/api/v2/video/' + vimeo_id(video_url) + '.json?callback=?', { format: "json" }, (data) => {
        resolve({img_src: data[0].thumbnail_large});
      });
    });
  }

  function init_thumbnail(element) {
    let video_url = element.data('url');
    let video_type = element.data('type');
    let alt = element.data('id');

    if (video_type === 'youtube') {
      element.html('<img src="'+ youtube_thumbnail(video_url) +'" alt="' + alt +'">');
    } else {
      vimeo_thumbnail(video_url).then((data) => {
        element.html('<img src="'+ data.img_src +'" alt="' + alt +'">');
      });
    }
  }

  $(".video-thumbnail").each(function() {
    init_thumbnail($(this))
  });

  // Header dropdowns

  const dropdownItems = document.querySelectorAll('header .has-dropdown-panel');

  if (dropdownItems) {
    dropdownItems.forEach(item => {
      const dropdownBtn = item.querySelector('button');
      const dropdownPanel = item.querySelector('.panel');

      if (dropdownBtn && dropdownPanel) {
        dropdownBtn.addEventListener('click', function() {
          const isExpanded = dropdownBtn.getAttribute('aria-expanded') === 'true';

          dropdownBtn.setAttribute('aria-expanded', !isExpanded);

          if (isExpanded) {
            dropdownPanel.classList.remove('is-active')
          } else {
            dropdownPanel.classList.add('is-active')

            dropdownItems.forEach(i => {
              if (i !== item) {
                i.querySelector('button').setAttribute('aria-expanded', 'false');
                i.querySelector('.panel').classList.remove('is-active');
              }
            });
          }
        });
      }
    });

    // Close dropdowns if we click outside
    document.addEventListener('click', function(e) {
      if (!e.target.closest('header')) {
        dropdownItems.forEach(item => {
          item.querySelector('button').setAttribute('aria-expanded', 'false');
          item.querySelector('.panel').classList.remove('is-active');
        });
      }
    });
  }

  // Menu mobile

  const menuBtn = document.querySelector('.mobile-menu-btn');
  const menu = document.querySelector('.navigation-container');

  if (menuBtn && menu) {
    menuBtn.addEventListener('click', function() {
      const isExpanded = menuBtn.getAttribute('aria-expanded') === 'true';
      menuBtn.setAttribute('aria-expanded', !isExpanded);
      menu.classList.toggle('is-active');

      if (menu.classList.contains('is-active')) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    });
  }

  // Tabs

  const tabs = document.querySelectorAll('[role="tab"]');
  const tabPanels = document.querySelectorAll('[role="tabpanel"]');

  if (tabs && tabPanels) {
    tabs.forEach(tab => {
      tab.addEventListener('click', function() {
        // Désactiver tous les onglets
        tabs.forEach(t => t.setAttribute('aria-selected', 'false'));
        tabPanels.forEach(panel => panel.setAttribute('aria-hidden', 'true'));

        // Activer l'onglet et son panneau associé
        this.setAttribute('aria-selected', 'true');
        const panel = document.getElementById(this.getAttribute('aria-controls'));
        panel.setAttribute('aria-hidden', 'false');
      });
    });
  }

});



(function() {
  if (typeof Turbolinks === "undefined" || Turbolinks === null) {
    location.reload;
  }

  Turbolinks.dispatch("turbolinks:load");
}).call(this);