import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    let counter = $('#notifications_count');
    let notifications = $('#notifications_list');

    // Mise à jour du compteur de notification
    if (counter.length === 0) {
      $('#notifications_count_wrapper').html("<span class=\"notification_count noti\" id=\"notifications_count\">1</span>");
      notifications.html("");
    } else {
      counter.html(parseInt(counter.text()) + 1);
    }

    // Ajout de la notification en tête de liste
    notifications.prepend(`
                  <a href="${data.url}">
                    <div class="notification">
                      <div class="notification__info">
                        <div class="info">
                          <p>
                            ${data.content}
                          </p>
                          <p class="time">À l'instant</p>
                        </div>
                      </div>
                    </div>
                  </a>`)
  }
});
