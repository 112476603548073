import consumer from "./consumer"

consumer.subscriptions.create("ChatroomsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Dans tous les cas, on affiche le message dans la conversation et on  met à jour l'affichage de la liste des
    // conversations sur le côté. Si l'utilisateur est sur la bonne page.

    // AFFICHAGE DU MESSAGE DANS LA SIDEBAR

    let body_preview;
    if (data.has_attachments) {
      body_preview = '<i class="fas fa-paperclip"></i> ' + data.body.substring(0, 101) + (data.body.length > 100 ? "..." : "")
    } else {
      body_preview = data.body.substring(0, 101) + (data.body.length > 100 ? "..." : "");
    }

    // Si l'utilisateur est bien sur l'une de ses conversations
    let my_chats = $("#conversation-list");
    if (my_chats.length > 0) {
      let chatroom_message_preview = $("[data-behavior='chatroom-message-preview'][data-chatroom-id='" + data.chatroom_id + "']");

      // Si l'aperçu de la conversation existait déjà avant
      if (chatroom_message_preview.length > 0) {
        // Modification de l'aperçu
        chatroom_message_preview.html(body_preview);
        $("#conversation-link-" + data.chatroom_id + " .message_time").html(`${data.just_now_string}`);

        // Déplacement de l'aperçu en haut de liste
        let conversation_list_link = $("#conversation-link-" + data.chatroom_id).first().parent();
        let content = conversation_list_link.prop("outerHTML");
        conversation_list_link.remove();
        my_chats.prepend(content);
      } else { // Sinon
        my_chats.prepend(`
                  <div class="conversation-list__link mb-2">
                    <a id="conversation-link-${data.chatroom_id}" href="/conversations/${data.chatroom_id}">
                        <div class="p-3 conversation-list__content unread">
                          <div class="pl-0 d-flex flex-row align-items-stretch">
                            ${data.recipient_profile_image}
                            
                            <div class="name_time d-flex justify-content-between align-items-stretch">
                              <div class="name mt-1 pl-3 text-overflow-ellipsis">${data.recipients}&nbsp;</div>
                              <span class="message_time mt-1">${data.just_now_string}</span>
                            </div>
                            
                            <p class="message_preview mt-1 ml-0 mr-0 mb-0 p-0" data-behavior="chatroom-message-preview" data-chatroom-id="${data.chatroom_id}">
                              ${body_preview}
                            </p>
                          </div>
                        </div>
                    </a>
                  </div>
                  `);
      }
    }

    // AFFICHAGE DU MESSAGE DANS LA CONVERSATION

    let active_chatroom = $("[data-behavior='messages'][data-chatroom-id='" + data.chatroom_id + "']");
    // Si l'utilisateur est bien sur la conversation du message en question
    if (active_chatroom.length > 0) {
      active_chatroom.append(data.message);

      // On scroll vers le bas pour voir le nouveau message
      let chat_area = $('.chat_area--conversation')[0];
      chat_area.scrollTop = chat_area.scrollHeight;
    }

    // AFFICHAGE DES ÉLÉMENTS DE NOTIFICATION

    // Si c'est un receveur, on ajoute la notification en haut
    if (data.type === "receiver") {
      // Notification dans le menu du haut
      $("#no_message").hide();
      let notification = $("[data-behavior='chatroom-message-notification'][data-chatroom-id='" + data.chatroom_id + "']");
      if (notification.length > 0) {
        notification.html(body_preview);
        let notification_link = $("#chatroom_notification_link_" + data.chatroom_id);
        $("#chatroom_notification_link_" + data.chatroom_id + " .message_data .name_time .time").html(`${data.just_now_string}`);
        let content = notification_link[0].outerHTML;
        notification_link.remove();
        $("#messages_notifications").prepend(content);
      } else {
        $("#messages_notifications").prepend(`
                  <a class="message recent ${data.type === "receiver" ? "background-blue-notification" : "" }" id="chatroom_notification_link_${data.chatroom_id}" href="/conversations/${data.chatroom_id}">
                    <div class="message_data">
                      <div class="name_time">
                        <div class="name">
                           <p>
                            ${data.sender_public_name}
                           </p>
                        </div>

                        <span class="time message_time">${data.just_now_string}</span>
                        <p data-behavior="chatroom-message-notification" data-chatroom-id="${data.chatroom_id}">
                            ${body_preview}
                        </p>
                      </div>
                    </div>
                  </a> `);
      }

      if (notification.length > 0) {
        let notification_link = $("#chatroom_notification_link_" + data.chatroom_id);
        notification_link.addClass("background-blue-notification");
      }

      if (active_chatroom.length > 0) {
        // Permet sur le script chatrooms.js, d'exécuter la requête ajax qui indique que l'utilisateur a lu les messages
        $("[data-behavior='messages']").removeClass("all_read");
      }

      // Mise à jour du pins de notification
      if (!data.already_notified) {
        let notification_count = $("#messages_notification_count");
        if (notification_count.length > 0) {
          notification_count.html(parseInt(notification_count.html()) + 1);
        } else {
          $("#messages_notification_icon").append("<span class=\"notification_count msg\" id=\"messages_notification_count\">1</span>")
        }
      }

      // Temps en bleu dans la liste des conversations
      let chatroom_link_content = $('#conversation-link-' + data.chatroom_id + ' .conversation-list__content');
      if (chatroom_link_content.length > 0) {
        chatroom_link_content.addClass('unread');
      }
    }
    // twemoji.parse(document.body);
  }

});